<template>
  <div>
    <el-dialog title="充值记录" :visible.sync="recordShow" width="80%" center :append-to-body="true">
      <div class="select_wrap">
        <el-input class="w290" v-model="searchData.shop_name" placeholder="请输入内容">
          <p slot="prefix" class="label_title">店铺名称</p>
        </el-input>
        <el-input class="w290" v-model="searchData.trade_no" placeholder="请输入内容">
          <p slot="prefix" class="label_title">订单编号</p>
        </el-input>
        <div >
          <el-select class="w290" v-model="searchData.status" placeholder="请选择" clearable filterable>
            <p slot="prefix" class="label_title">支付状态</p>
            <el-option v-for="item in sqArr" :key="item.value" :label="item.name" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div style="margin-top: 10px">
        <el-button plain size="small" @click="onSearch()">查询</el-button>
        <el-button plain size="small" @click="resetSearch()">重置</el-button>
      </div>
      <div class="table_wrap" style="margin-top: 10px">
        <el-table :data="tableData" style="width: 100%;" height="60vh">
          <el-table-column prop="shop_name" label="店铺名称">
          </el-table-column>
          <el-table-column prop="trade_no" label="订单号">
          </el-table-column>
          <el-table-column prop="price" label="价格">
          </el-table-column>
          <el-table-column prop="num" label="铺货次数">
          </el-table-column>
          <el-table-column prop="num" label="状态">
            <template slot-scope="scope">
              <span v-if="scope.row.status==1">待支付</span>
              <span v-if="scope.row.status==2">支付成功</span>
              <span v-if="scope.row.status==3">支付失败</span>
            </template>
          </el-table-column>
          <el-table-column prop="create_at" label="创建时间">
            <template slot-scope="scope">
              {{ scope.row.create_at > 0 ? common._date(scope.row.create_at,'Y-m-d H:i:s') : '--'}}
            </template>
          </el-table-column>
          <el-table-column prop="update_at" label="更新时间">
            <template slot-scope="scope">
              {{ scope.row.update_at > 0 ? common._date(scope.row.update_at,'Y-m-d H:i:s') : '--'}}
            </template>
          </el-table-column>
          <el-table-column prop="" label="操作">
            <template slot-scope="scope">
              <el-button type="text" v-if="scope.row.status==1" :loading="loading[scope.row.trade_no]" @click="onCheck(scope.row.trade_no)">充值校验</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="turn_page">
        <el-pagination
            :page-size="page_size"
            layout="total,prev, pager, next, jumper"
            prev-text="上一页"
            next-text="下一页"
            :total="listTotal"
            hide-on-single-page
            :current-page="page"
            @current-change="currentChange">
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions,mapState,mapMutations } from "vuex";
import common from '../../assets/js/utils/common.js';
export default {
  name: "ucenterWallet",
  data() {
    return {
      recordShow:false,
      searchData:{},
      sqArr:[
          {name:"待支付",value:1},
          {name:"支付成功",value:2},
          {name:"支付失败",value:3}
      ],
      tableData: [],
      page:1,
      page_size:10,
      listTotal:0,
      common:common,
      loading: {

      }
    }
  },
  created(){

  },
  methods: {
    ...mapActions({
      getQuotaList:"ucenter/getQuotaList",
      packageCheckOrder: "ucenter/getpackageCheckOrder",
    }),
    show(){
      this.getQuota()
      this.recordShow = true
    },
    getQuota(){//获取钱包记录流水
      let params = {
        limit:this.page_size,
        page:this.page
      }
      params = Object.assign(params,this.searchData)
      this.getQuotaList({data:params,success:(res)=>{
          if(res.code===200){
            this.tableData = res.data.data
            this.listTotal = res.data.total
          } else {
            this.$message({message: res.msg,type: 'error',});
          }
        }})
    },
    currentChange(val){
      this.page = val
      this.getQuota()
    },
    onSearch(){
      this.page = 1
      this.getQuota()
    },
    resetSearch(){
      this.page = 1
      this.searchData = {}
      this.getQuota()
    },
    onCheck(no){
      if(this.loading[no]){
        return true;
      }
      this.$set(this.loading,no,true)
      let param = {trade_no:no}
      this.packageCheckOrder({
        data: param, success: (res) => {
          this.$set(this.loading,no,false)
          if (res.code == 200) {
            this.$message.success(res.msg)
            this.getQuota()
          } else {
            this.$message({message: res.msg, type: 'error'})
          }
        }
      })
    },
  }
}
</script>

<style scoped>
.select_wrap{display: flex;align-items:center;flex-wrap: wrap;margin-top: 14px}
.select_wrap .mr{margin-right: 15px;}
.select_wrap .w290{width: 290px;margin-top: 14px;margin-right: 15px;position: relative;}
.select_wrap .cate-label{position: absolute;left: 5px;z-index: 1;}
.el-cascader{line-height: 34px;}
.select_wrap .label_title{width: 76px;height: 100%;font-size: 12px;color: #999;display: flex;align-items: center;padding-left: 6px;}




.wrap .wrap_body{display: flex;flex-direction: column;}
.center{display: flex;flex: 1;}
.ucenter_main{padding: 30px 0 40px;display: flex;justify-content: space-between;flex: 1;}
.right_con{width: 980px;}
.wallet_main{padding: 0 40px 36px;background: #fff;display: flex;}
.wallet_main .ye_wrap{margin-top: 36px;}
.wallet_main .ye_wrap .balance{margin-top: 30px;font-weight: bold;font-size: 30px;}
.wallet_main .ye_wrap .balance>em{font-size: 50px;}
.wallet_main .ye_wrap .btn_wrap{display: flex;margin-top: 40px;}
.wallet_main .ye_wrap .btn_wrap .btn{height: 40px;border-radius: 6px;display: flex;justify-content: center;align-items: center;font-size: 14px;font-weight: bold;box-sizing: border-box;cursor: pointer;}
.wallet_main .ye_wrap .btn_wrap .btn:nth-child(1){width: 135px;background: #f0413e;color: #fff;margin-right: 15px;}
.wallet_main .ye_wrap .btn_wrap .btn:nth-child(2){width: 115px;background: #fff;color: #f0413e;border: 1px solid #f0413e;}
.wallet_main .dk_wrap{margin-left: 180px;margin-top: 76px;}
.wallet_main .dk_wrap>p .icon-suo{font-size: 16px;color: #333;margin-right: 12px;}
.wallet_main .dk_wrap>p>em{font-size: 18px;font-weight: bold;margin-left: 8px;}
.wallet_main .dk_wrap>p .icon-shuoming{font-size: 16px;color: #558ed5;margin-left: 8px;cursor: pointer;}
.wallet_main .dk_wrap>p:nth-child(2){margin-top: 18px;}
.record_main{margin-top: 16px;padding: 30px 40px 40px;background: #fff;line-height: 1;}
.record_main .screen_wrap{margin-top: 12px;height: 50px;border-bottom: 1px solid #eee;display: flex;justify-content: space-between;}
.screen_wrap .left_tabs{display: flex;height: 100%;width: 50%;}
.screen_wrap .left_tabs .tab{height: 100%;position: relative;cursor: pointer;display: flex;align-items: center;flex: 1;justify-content: center;}
.screen_wrap .left_tabs .tab>span{font-size: 14px;color: #333333;}
.screen_wrap .left_tabs .tab.active::after{content: '';width: 100%;height: 3px;background: var(--red);position: absolute;left: 0;bottom: 0;z-index: 2;}
.screen_wrap .left_tabs .tab.active>span{color: var(--red);font-weight: bold;}
.screen_wrap .left_tabs .tab:last-child>span{border-right: none;}
.screen_wrap .right_time{display: flex;justify-content: flex-end;align-items: center;}
.screen_wrap .right_time .txt{font-size: 14px;color: #666666;margin: 0 10px;display: flex;justify-content: center;align-items: center;}
.turn_page{display: flex;justify-content: flex-end;margin-top: 30px;}
.tixian_main{box-sizing: border-box;background: #fff;}
.tixian_main .icon-guanbi{font-size: 12px;color: #999;position: absolute;top: 16px;right: 16px;cursor: pointer;z-index: 222;}
.tixian_main .title{font-size: 20px;line-height: 1;}
.tixian_main .title .icon-qian{margin-right: 8px;font-size: 24px;color: #f38f00;}
.tixian_main .title .t{font-size: 12px;color: #666666;}
.tixian_main .title .t>em{color: var(--red);}
.tixian_main .tx_count{margin: 24px 0 6px;height: 80px;background: #f5f5f5;border-radius: 4px;box-sizing: border-box;padding: 0 18px;display: flex;align-items: center;}
.tixian_main .tx_count .in{font-size: 30px;color: #333;font-weight: bold;flex: 1;width: 0;}
.tixian_main .tx_count .in::placeholder{font-size: 16px;color: #cccccc;line-height: 34px;}
.tixian_main .tx_count .tx{font-size: 12px;color: #f0413e;margin-left: 18px;cursor: pointer;}
.tixian_main .input_line{margin: 10px 0 0;height: 36px;box-sizing: border-box;border: 1px solid #ddd;border-radius: 4px;display: flex;padding: 0 10px;align-items: center;}
.tixian_main .input_line .txt{width: 74px;font-size: 12px;color: #999999;}
.tixian_main .input_line .in{flex: 1;width: 0;font-size: 12px;color: #333;}
.tixian_main .input_line .getcode{padding-left: 8px;border-left: 1px solid #eee;font-size: 12px;color: #333333;cursor: pointer;line-height: 1;}
.tixian_main .el_in2{margin: 14px 0 0;}
.tixian_main .btn_wrap{margin: 36px 0 0;display: flex;justify-content: space-between;}
.tixian_main .btn_wrap .btn{width: 160px;height: 40px;box-sizing: border-box;border-radius: 6px;display: flex;justify-content: center;align-items: center;font-size: 14px;cursor: pointer;}
.tixian_main .btn_wrap .btn:nth-child(1){border: 1px solid #dddddd;color: #333;}
.tixian_main .btn_wrap .btn:nth-child(2){background: #f0413e;color: #fff;}
.chongzhi_main{box-sizing: border-box;background: #fff;}
.chongzhi_main .icon-guanbi{font-size: 12px;color: #999;position: absolute;top: 16px;right: 16px;cursor: pointer;z-index: 222;}
.chongzhi_main .title{display: flex;align-items: center;margin: 0 22px;font-size: 20px;line-height: 1;}
.chongzhi_main .title .icon{width: 22px;height: 22px;margin-right: 8px;}
.chongzhi_main .input_line{margin: 15px 22px 0;display: flex;align-items: center;}
.chongzhi_main .input_line .txt{font-size: 12px;color: #666666;margin-right: 10px;}
.chongzhi_main .input_line .in{width: auto;}
.chongzhi_main .code{width: 155px;height: auto;display: block;margin: 30px auto 0;}
.chongzhi_main .btn_wrap{margin: 45px 0 0;display: flex;justify-content: space-between;}
.chongzhi_main .btn_wrap .btn{width: 160px;height: 40px;box-sizing: border-box;border-radius: 6px;display: flex;justify-content: center;align-items: center;font-size: 14px;cursor: pointer;}
.chongzhi_main .btn_wrap .btn:nth-child(1){border: 1px solid #dddddd;color: #333;}
.chongzhi_main .btn_wrap .btn:nth-child(2){background: #f0413e;color: #fff;}

.el-date-editor{margin-top: unset !important;}
</style>
<style>

.tixian_mask .el-dialog__body{padding-top: 0;}
.el_input{display: flex;margin: 14px 32px 0;width: initial;}
.el_input .el-input__inner{height: 38px;line-height: 38px;padding-left: 58px;flex: 1;width: 0;}
.el_input .el-input__inner:focus{border-color: var(--red);}
.el_input.el_in2 .el-input__inner{padding-left: 100px;}
.el_input .el-input__prefix{left: 2px;top: 1px}
.el_input .el-input__prefix .icons{width: 38px;height: 36px;display: flex;justify-content: center;align-items: center;top: 1px;left: 2px;background: #f5f5f5;border-right: 1px solid #ddd;border-radius: 4px 0 0 4px;}
.el_input .el-input__prefix .icons .iconfont{font-size: 20px;color: #cccccc;}
.el_input .el-input__prefix .txts{width: 100%;height: 100%;display: flex;align-items: center;padding-left: 10px;}
.el_input .el-input__suffix{display: flex;}
.el_input .el-input__suffix .el-input__suffix-inner{display: flex;align-items: center;}
.el_input .el-input__suffix .el-input__suffix-inner .getcode{font-size: 14px;color: #333333;padding: 0 12px;border-left: 1px solid #ccc;line-height: 1;cursor: pointer;}

.screen_wrap .right_time .el-input__inner{height: 36px;line-height: 36px;font-size: 12px;padding: 0 8px;border-radius: 4px;width: auto;margin-right: 14px;}
.screen_wrap .right_time .el-date-editor .el-range__icon{color: #666;line-height: 36px;}
.screen_wrap .right_time .el-range-editor .el-range-input{width: 120px;line-height: 36px;color: #333;}
.screen_wrap .right_time .el-date-editor .el-range-separator{color: #666;line-height: 36px;font-size: 12px;}
.screen_wrap .right_time .el-date-editor .el-range__close-icon{line-height: 36px;display: none;}
.el-button--text{padding: 7px 15px !important;}
.el-button--text:hover{border-color: var(--red);color: var(--red);}
.el-table .el-table__cell{text-align: center;padding: 14px 0;}
.el-table thead .el-table__cell{padding: 3px 0;background: #f9f9f9;color: #666;border-bottom: none !important;}
.chongzhi_main .input_line .in .el-input__inner{width: 250px;height: 30px;box-sizing: border-box;padding: 0 10px;font-size: 14px;color: #333;}

.el-button{color: #333;border-color: #ddd;}
.el-button--small{padding: 7px 15px;}
.el-checkbox{color: #666;display: flex;align-items: center;}
.el-checkbox__inner{border-color: #ddd;width: 16px;height: 16px;}
.el-checkbox__input{display: flex;}
.el-checkbox__label{font-size: 12px;line-height: 1;}
.el-checkbox__inner::after{height: 9px;left: 5px;}
.el-checkbox__input.is-indeterminate .el-checkbox__inner::before{height: 4px;}

/*red border color*/
.el-button.is-plain:focus, .el-button.is-plain:hover ,.el-checkbox__inner:hover ,.el-checkbox__input.is-focus .el-checkbox__inner,.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner, .el-pagination__jump .el-input__inner:focus ,.el-input__inner:focus ,.el-range-editor.is-active, .el-range-editor.is-active:hover{border-color: var(--red);}

/*red color*/
.el-button.is-plain:focus, .el-button.is-plain:hover, .el-checkbox__input.is-checked+.el-checkbox__label, .el-pager li.active ,.el-pagination button:hover ,.el-pager li:hover ,.el-date-table td.today span ,.el-date-table td.available:hover ,.el-time-panel__btn.confirm{color: var(--red);}

/*red background color*/
.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner ,.el-date-table td.end-date span, .el-date-table td.start-date span{background-color: var(--red);}

</style>
